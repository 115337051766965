html {
  width: 100%;
  height: 100%;
}

body {
  background: center url("assets/bg.jpg") fixed;
  background-size: cover;
  padding-bottom: 50px;
}

.homePadding {
  padding-top: 80px;
  padding-bottom: 2em;
}

.logoStart {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 1em;
}

.tuto__title {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5% 0% 5% 0%;
  text-align: center;
  font-size: x-large;
}

.tuto__step {
  display: flex;
  align-items: center;
  justify-content:space-around;
  margin: 5% 0% 5% 0%;
  background-color: #2972a4;
  border-radius: 20px;
}


.tuto__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  text-align: center;
  margin-right: 20px;
  background:linear-gradient(to bottom, #8d76ab 5%, #8b79a3 100%);
	background-color:#8d76ab;
	border-radius:27px;
	border:1px solid white;
}

.tuto__right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tuto__right img {
  object-fit: contain;
  height: 245px;  
  width: 427px;
  border-radius: 12px;
}

.tuto__number {
  font-weight: bold;
  font-size: x-large;
  color: white;
  margin:  5px 0px 5px 0px;
  padding: 15px 15px 15px 15px;
  border: 2px solid white;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 1% 1% 1%;
}

.tuto__text {
  margin: 0% 0% 1% 0%;
  font-weight: bold;
  text-decoration: none;
  color: black;
  padding: 1% 1% 1% 1%;
}

.tuto__text a {
  color: black;
  text-decoration: underline;
}

.tuto__text2 {
  font-size: small;
  font-weight: bold;
  color: black;
}

.tuto__row1 {
  position: relative;
  text-align: center;
}

.tuto__world {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: x-large;
}

.tuto__row2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tuto__video {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}